/* Scrollbar styling */

::-webkit {
  &-scrollbar {
    width: 0.4rem;

    &-track {
      border-radius: 5rem;
      background: darken($color-white, 5%);
    }

    &-thumb {
      border-radius: 5rem;
      background: var(--primary-color);

      &:hover {
        background: var(--primary-color-darker);
      }
    }

    @include small-up {
      width: rs(4);

      &-track,
      &-thumb {
        border-radius: rs(50);
      }
    }
  }
}
