//// Color Variables

$color-text: #000000;
$color-text-inverse: #ffffff;
$color-black: #000000;
$color-white: #ffffff;
$color-grey: #aeaeae;

//// Text Variables
$font-family-title: "Calibri", "Candara", "Segoe", "Segoe UI", "Optima", Arial, sans-serif;
$font-family-text: "Calibri", "Candara", "Segoe", "Segoe UI", "Optima", Arial, sans-serif;

$text-size-mobile: 1.6rem;
$text-size: rs(16);

$subtitle-size-mobile: 2rem;
$subtitle-size: rs(20);

$title-size-mobile: 2.4rem;
$title-size: rs(24);

//// Interface Variables
$ui-padding-mobile: 2.4rem;
$ui-padding: rs(24);

//// Animation Variables
$basic-trans-100: 100ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-trans-200: 200ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-trans-300: 300ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-trans-500: 500ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-trans-1000: 1s cubic-bezier(0.86, 0, 0.07, 1);

$bounce-trans-200: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
$bounce-trans-300: 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
$bounce-trans-500: 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
$bounce-trans-1000: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
