
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          

























































.languages {
  padding: rs(2);
  display: flex;
  flex-direction: column;
  color: $color-text-inverse;
  z-index: 1;

  a {
    cursor: pointer;
    opacity: 0.9;
    text-transform: uppercase;
    margin: 0.6rem 0;

    & ~ a::before {
      content: "";
      margin: 0;
      pointer-events: none;
      font-weight: normal !important;
    }

    &.selected {
      pointer-events: none;
    }

    &.selected,
    &:hover {
      opacity: 1;
      font-weight: bold;
    }

    &:hover::before {
      font-weight: normal !important;
      opacity: 0.9;
    }
  }

  @include small-up {
    flex-direction: row;

    a {
      margin: 0;

      & ~ a::before {
        content: "|";
        margin: 0 rs(6);
      }
    }
  }
}
