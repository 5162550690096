
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          

















































span {
  display: inline-block;

  a {
    display: inline;
    padding: 0 1.2rem;
    transition: font-weight $basic-trans-100;

    &:hover {
      font-weight: bold;
    }

    @include small-up {
      padding: 0 rs(12);
    }
  }
}
