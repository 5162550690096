@import "./components/element.scss";

.gui {
  z-index: 1001;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  * {
    pointer-events: all;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all $basic-trans-200;
    opacity: 1;
    width: 15%;
    min-width: 10rem;
    max-width: 15rem;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    &:hover {
      opacity: 0.8;
    }

    @include small-up {
      width: 10%;
      min-width: rs(100);
      max-width: rs(125);
    }
  }

  &__title {
    bottom: 2.4rem;
    font-weight: bold;
    border-radius: 0 0.8rem 0.8rem 0;
    word-break: break-all;
    transition: letter-spacing $basic-trans-300;
    max-width: calc(100% - 10rem);

    * {
      display: flex;
      width: auto;
      word-break: normal;
      color: $color-text-inverse !important;
    }

    h1,
    h2 {
      text-transform: uppercase;
      margin-top: rs(6);
    }

    p {
      margin-top: rs(12);
      max-width: rs(500);
    }

    @include small-up {
      bottom: rs(72);
      border-radius: 0 rs(8) rs(8) 0;
      max-width: calc(100% - rs(100));
    }
  }

  &__webvr {
    display: none;

    @include small-up {
      display: block;
    }
  }
}

.side-menu {
  &__button {
    padding: 0 0 1.2rem 1.2rem;

    &-icon {
      position: relative;
      width: 3.2rem;
      height: 2.4rem;

      .line {
        display: block;
        width: 100%;
        height: 0.3rem;
        right: 0;
        position: absolute;
        background-color: $color-text-inverse;
        transition: width $bounce-trans-200;

        &:nth-of-type(1) {
          top: 0;
        }

        &:nth-of-type(2) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:nth-of-type(3) {
          bottom: 0;
        }
      }
    }

    &:hover {
      .line {
        &:first-of-type {
          width: 80%;
        }

        &:last-of-type {
          width: 60%;
        }
      }
    }

    @include small-up {
      padding: 0 0 rs(12) rs(12);

      &-icon {
        width: rs(32);
        height: rs(24);

        .line {
          height: rs(3);
        }
      }
    }
  }
}
