/* Flicking styling */

.gallery {
  .flicking-camera {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
  }
}

.modal {
  .flicking-camera {
    display: inline-flex !important;
  }
}
