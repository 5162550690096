/////////////////// Poppins
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/////////////////// Roboto
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

html {
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    font-family: $font-family-text;
    font-size: $text-size-mobile;
    color: $color-text;
    font-weight: normal;

    @include small-up {
      font-size: $text-size;
    }
  }
}

h1,
h1 span {
  font-weight: bold;
  font-size: $title-size-mobile;

  @include small-up {
    font-size: $title-size;
  }
}

h2,
h2 span {
  font-weight: bold;
  font-size: $subtitle-size-mobile;

  @include small-up {
    font-size: $subtitle-size;
  }
}
