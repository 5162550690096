
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          











































.actions {
  position: absolute;
  bottom: $ui-padding-mobile;
  right: $ui-padding-mobile;
  display: grid;
  grid-gap: 1.6rem;
  grid-template: 1fr 1fr / 1fr;

  img,
  a {
    display: block;
    width: 2.5rem;
    opacity: 0.8;
    transition: opacity $basic-trans-200;

    &:hover {
      opacity: 1;
    }
  }

  .gallery {
    transform: scale(0.8);
  }

  @include small-up {
    bottom: $ui-padding;
    right: rs(48);
    grid-gap: rs(16);
    grid-template: 1fr / 1fr 1fr;

    img,
    a {
      width: rs(25);
    }
  }
}

.credit {
  font-size: 1.2rem;
  width: 1rem;
  height: 1rem;
  display: block;
  visibility: visible;
  background-color: transparent;
  top: auto;
  z-index: 3;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  color: #fff;
  letter-spacing: 0;
  text-decoration: none;
  white-space: nowrap;
  opacity: 0.5;
  box-shadow: 0 0 12rem 1rem #00000047;
  display: none;

  &:hover {
    text-decoration: underline;
  }

  @include small-up {
    display: block;
    font-size: rs(12);
    width: rs(10);
    height: rs(10);
    box-shadow: 0 0 rs(120) rs(10) #00000047;
  }
}
