.krpano__wrapper {
  background: var(--accent-color);
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    padding: 0;
    margin: 0;
  }

  // CSS Krpano hack
  & #krpanoObject {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    & > div {
      & > div:last-child {
        & > div {
          // #hotspot__wrapper
          width: 0 !important;
          height: 0 !important;
          background-color: transparent !important;
          border: rs(1) solid transparent;

          &:hover,
          &.locator__touched {
            z-index: 2005 !important;
          }

          & > div {
            & > div {
              overflow: visible !important;

              & > div {
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

#krpano {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: transparent;
}
