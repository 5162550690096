.gui {
  &-fade-enter-active,
  &-fade-leave-active {
    transition: opacity $basic-trans-500 0.1s;
  }

  &-fade-enter,
  &-fade-leave-to {
    opacity: 0;
  }
}

.splash {
  &-fade-enter-active,
  &-fade-leave-active {
    transition: opacity $basic-trans-1000;
  }

  &-fade-enter,
  &-fade-leave-to {
    opacity: 0;
  }
}

.loading {
  &-fade-enter-active,
  &-fade-leave-active {
    transition: opacity 0.1s linear;
  }

  &-fade-enter,
  &-fade-leave-to {
    opacity: 0;
  }
}

.dots {
  &-title-fade-enter-active,
  &-title-fade-leave-active {
    transition: opacity 0.1s linear;
  }

  &-title-fade-enter,
  &-title-fade-leave-to {
    opacity: 0;
  }
}

.readmore {
  &-text-fade-enter-active,
  &-text-fade-leave-active {
    transition: opacity 0.1s linear;
  }

  &-text-fade-enter,
  &-text-fade-leave-to {
    opacity: 0.3;
  }
}
