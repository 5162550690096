
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          
@import "./scss/components/scrollbar.scss";
@import "./scss/components/tinybox.scss";
@import "./scss/components/flicking.scss";
@import "./scss/common/transitions.scss";

html,
body,
#app {
  height: 100%;
}

.invisible {
  opacity: 0 !important;
  pointer-events: none !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: -1 !important;
}
