/* Tinybox styling */

.tinybox {
  z-index: 8000 !important;
  background: rgba(0, 0, 0, 1) !important;

  &__content {
    &__image {
      border-radius: 0.4rem !important;
    }

    &__control--close {
      top: 1.6rem !important;
    }

    @include small-up {
      &__image {
        border-radius: rs(4) !important;
      }

      &__control--close {
        top: rs(16) !important;
      }
    }
  }
}
