
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          
































.home {
  .languages a {
    color: $color-text;
  }
}
