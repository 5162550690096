.hotspot {
  // Variable setup
  --hotspot-dimension: 3.2rem;
  --hotspot-sub: 1rem;
  --hotspot-text: 1.4rem;
  --hotspot-padding: 0 2rem 0 4.8rem;

  @include medium-up {
    --hotspot-dimension: 3.6rem;
    --hotspot-sub: 1.4rem;
    --hotspot-text: 1.5rem;
    --hotspot-padding: 0 2.2rem 0 5rem;
  }

  @include large-up {
    --hotspot-dimension: 4rem;
    --hotspot-sub: 1.8rem;
    --hotspot-text: 1.6rem;
    --hotspot-padding: 0 2.4rem 0 5.2rem;
  }

  @include xxlarge-up {
    --hotspot-dimension: 4.6rem;
    --hotspot-sub: 2.4rem;
    --hotspot-text: 2.2rem;
    --hotspot-padding: 0 3.6rem 0 6.4rem;
  }

  // Krpano hack
  position: absolute;
  cursor: pointer !important;
  pointer-events: all;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5rem;
  box-shadow: 0 0 3.2rem 0.8rem rgba(0, 0, 0, 0.2);
  background: $color-white;
  display: grid;
  place-content: center;
  transition: all $basic-trans-200;

  // Appear animation
  opacity: 0;
  width: calc(var(--hotspot-dimension) - var(--hotspot-sub));
  height: calc(var(--hotspot-dimension) - var(--hotspot-sub));
  animation: hotspot-appear $bounce-trans-300 forwards;

  @include small-up {
    box-shadow: 0 0 rs(32) rs(8) rgba(0, 0, 0, 0.2);
    border-radius: rs(50);
  }

  @keyframes hotspot-appear {
    from {
      opacity: 0;
      width: calc(var(--hotspot-dimension) - var(--hotspot-sub));
      height: calc(var(--hotspot-dimension) - var(--hotspot-sub));
    }
    to {
      opacity: 1;
      width: var(--hotspot-dimension);
      height: var(--hotspot-dimension);
    }
  }

  &__info,
  &__small-info {
    background: var(--accent-color);
  }

  &__inner {
    &__navigation {
      width: calc(var(--hotspot-dimension) / 3);
      height: calc(var(--hotspot-dimension) / 3);
      border-radius: 5rem;
      background: var(--primary-color);
      transition: all $basic-trans-200;
      overflow: hidden;
      display: grid;
      place-content: center;

      img {
        opacity: 0;
        height: 100%;
        width: 100%;
        transition: all $basic-trans-200;
      }

      @include small-up {
        border-radius: rs(50);
      }
    }

    &__info,
    &__small-info {
      background: var(--secondary-color);
      width: var(--hotspot-dimension);
      height: var(--hotspot-dimension);
      border-radius: 5rem;

      img {
        height: 100%;
        width: 100%;
        transition: all $basic-trans-200;
        filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg)
          brightness(106%) contrast(106%);
      }

      @include small-up {
        border-radius: rs(50);
      }
    }
  }

  &__content {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    height: var(--hotspot-dimension);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    overflow: hidden;
    transition: all $basic-trans-200;
    padding: var(--hotspot-padding);
    border-radius: 5rem 2rem 2rem 5rem;

    @include small-up {
      border-radius: rs(50) rs(20) rs(20) rs(50);
    }

    span {
      opacity: 0;
      transition: all $basic-trans-1000;
    }

    &__bg {
      background: var(--primary-color);
      width: 0%;
      height: 100%;
      left: 0;
      transition: all $basic-trans-500;
      position: absolute;
      z-index: -1;
    }

    &--small-info {
      flex-direction: column;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: -1;
      background-color: $color-white;
      opacity: 0;
      display: flex;
      align-items: center !important;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      min-width: 25rem;
      max-width: 40rem;
      padding: 2.4rem 1.2rem 1.2rem 1.2rem;
      border-radius: 0.4rem;
      box-shadow: 0px 8px 32px 1px rgba(0, 0, 0, 0.1);
      transition: all 0.2s linear;

      * {
        word-break: break-word;
      }

      span {
        color: $color-black;
        font-weight: bold;
        margin-bottom: 1.2rem;
        font-size: 1.6rem;
        white-space: break-spaces;
        transition: all $basic-trans-500;
      }

      p {
        font-weight: normal;
        opacity: 0;
        font-size: 1.4rem;
        transition: all $basic-trans-500;
        white-space: break-spaces;
      }

      @include small-up {
        min-width: rs(250);
        max-width: rs(400);
        border: rs(4) solid $color-white;
        padding: rs(24) rs(12) rs(12) rs(12);
        border-radius: rs(4);
        box-shadow: 0px rs(8) rs(32) rs(1) rgba(0, 0, 0, 0.1);

        span {
          margin-bottom: rs(12);
          font-size: rs(16);
        }

        p {
          font-size: rs(14);
        }
      }
    }

    * {
      color: $color-text-inverse;
      font-weight: bold;
      font-size: var(--hotspot-text);
    }
  }

  &:hover,
  &.touched {
    .hotspot__inner {
      background: var(--secondary-color);
      width: calc(var(--hotspot-dimension) - 0.6rem);
      height: calc(var(--hotspot-dimension) - 0.6rem);

      img {
        opacity: 1;
        filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg)
          brightness(106%) contrast(106%);
      }
    }

    .hotspot__content {
      opacity: 1;
      pointer-events: all;

      span,
      p {
        opacity: 1;
      }

      &__bg {
        width: 100%;
      }
    }
  }
}
