
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          






















.button {
  max-width: 25rem;
  background: var(--primary-color);
  background-size: 200% 110%;
  background-position: right bottom;
  border-radius: 50rem;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  padding: 1.6rem 2.4rem;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  overflow: hidden;

  transition: all $basic-trans-300;

  &:hover {
    background: var(--primary-color-lighter);
  }

  @include small-up {
    max-width: rs(250);
    border-radius: rs(500);
    padding: rs(16) rs(24);
  }
}
