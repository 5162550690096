.gui__element {
  position: absolute;

  &--top-right {
    top: $ui-padding-mobile;
    right: $ui-padding-mobile;

    @include small-up {
      top: $ui-padding;
      right: $ui-padding;
    }
  }

  &--top-left {
    top: $ui-padding-mobile;
    left: $ui-padding-mobile;

    @include small-up {
      top: $ui-padding;
      left: $ui-padding;
    }
  }

  &--bottom-right {
    bottom: $ui-padding-mobile;
    right: $ui-padding-mobile;

    @include small-up {
      bottom: $ui-padding;
      right: $ui-padding;
    }
  }

  &--bottom-left {
    bottom: $ui-padding-mobile;
    left: $ui-padding-mobile;

    @include small-up {
      bottom: $ui-padding;
      left: $ui-padding;
    }
  }

  &--bottom-center {
    left: 50%;
    transform: translateX(-50%);
    bottom: $ui-padding-mobile;

    @include small-up {
      bottom: $ui-padding;
    }
  }

  &--top-center {
    left: 50%;
    transform: translateX(-50%);
    top: $ui-padding-mobile;

    @include small-up {
      top: $ui-padding;
    }
  }
}
