// https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862#.adpufyefv

$breakpointMap: (
  "for-tablet-portrait-up": "600px",
  "for-tablet-landscape-up": "900px",
  "for-desktop-up": "1200px",
  "for-medium-desktop-up": "1600px",
  "for-big-desktop-up": "1800px",
  "for-giant-desktop-up": "2000px",
);

@mixin xsmall-up {
  @media (min-width: map-get($breakpointMap, "for-tablet-portrait-up")) {
    @content;
  }
}

@mixin small-up {
  @media (min-width: map-get($breakpointMap, "for-tablet-landscape-up")) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: map-get($breakpointMap, "for-desktop-up")) {
    @content;
  }
}

@mixin large-up {
  @media (min-width: map-get($breakpointMap, "for-medium-desktop-up")) {
    @content;
  }
}

@mixin xlarge-up {
  @media (min-width: map-get($breakpointMap, "for-big-desktop-up")) {
    @content;
  }
}

@mixin xxlarge-up {
  @media (min-width: map-get($breakpointMap, "for-giant-desktop-up")) {
    @content;
  }
}

@mixin mediaquery($key) {
  @if (map-has-key($breakpointMap, $key)) {
    @media (min-width: map-get($breakpointMap, $key)) {
      @content;
    }
  } @else {
    @error "Breakpoint '#{$key}' doesn't exist";
  }
}
