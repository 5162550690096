
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          

























































.socials {
  display: grid;
  flex-direction: column;
  grid-gap: 1.2rem;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    position: relative;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      transition: opacity $basic-trans-200;
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(50deg)
        brightness(110%) contrast(100%);
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }

  @include small-up {
    grid-gap: rs(12);

    a {
      width: rs(32);
      height: rs(32);
    }
  }
}
