.hotspot {
  &__outer {
    // Variable setup
    --outer-dimension: 4rem;
    --outer-sub: 1rem;

    @include medium-up {
      --outer-dimension: 4.4rem;
      --outer-sub: 1.4rem;
    }

    @include large-up {
      --outer-dimension: 4.8rem;
      --outer-sub: 1.8rem;
    }

    @include xxlarge-up {
      --outer-dimension: 5.6rem;
      --outer-sub: 2.4rem;
    }

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba($color-black, 0.5);
    z-index: -2;
    border-radius: 50%;

    // Appear animation
    opacity: 0;
    width: calc(var(--outer-dimension) - var(--outer-sub));
    height: calc(var(--outer-dimension) - var(--outer-sub));
    animation: outer-appear $basic-trans-200 0.2s forwards;
    transition: all $basic-trans-300;

    @keyframes outer-appear {
      from {
        opacity: 0;
        width: calc(var(--outer-dimension) - var(--outer-sub));
        height: calc(var(--outer-dimension) - var(--outer-sub));
      }
      to {
        opacity: 1;
        width: var(--outer-dimension);
        height: var(--outer-dimension);
      }
    }
  }

  &__wrapper:hover .hotspot__outer,
  &.touched + .hotspot__outer {
    width: var(--outer-sub) !important;
    height: var(--outer-sub) !important;
  }
}
