
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          













.loader {
  // width: 5rem;
  // height: 5rem;
  // border-radius: 50%;
  // display: inline-block;
  // vertical-align: middle;
  // position: relative;
  // animation-delay: 1s;
  // background: lighten($color-white, 10%);
  // animation: loader $basic-trans-1000 infinite;

  &__wrapper {
    background-color: rgba(var(--accent-color), 0.5);
    z-index: 1002;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    place-items: center;
    place-content: center;
    backdrop-filter: blur(5px) brightness(1.3);
  }

  @include small-up {
    width: rs(50);
    height: rs(50);
  }
}

@keyframes loader {
  0%,
  80%,
  100% {
    opacity: 0.4;
    transform: scale(1);
  }
  40% {
    opacity: 0.8;
    transform: scale(1.5);
  }
}
